@tailwind base;
@tailwind components;
@tailwind utilities;

h1 {
    @apply text-3xl font-bold;
}

html,
body {
    font-size: 14px;
    @apply !bg-back;
}

@font-face {
    font-family: Griff;
    src: url("../public/webFonts/GriffRegular/Griff-Regular.ttf");
}

@font-face {
    font-family: Proxima Nova Regular;
    src: url("../public/webFonts/ProximaNovaRegular/font.woff");
}
@font-face {
    font-family: Proxima Nova Semibold;
    src: url("../public/webFonts/ProximaNovaSemibold/font.woff");
}

* {
    font-family: Proxima Nova Regular;
}

.react-datetime-picker__wrapper {
    background-color: white;
    padding: 8px;
    border-radius: 8px;
}

abbr:where([title]) {
    text-decoration: none;
}

.react-calendar__month-view__weekdays__weekday {
    color: #777;
}

.react-calendar__navigation {
    margin-bottom: 2px !important;
    border-bottom: 1px solid #ccc;
}

.react-calendar__navigation__label__labelText {
    display: block;
    padding-top: 4px !important;
}

.react-calendar__navigation__label__labelText,
.react-calendar__navigation button {
    background-color: unset !important;
    color: #333;
    font-size: 14px;
}

.react-datetime-picker__calendar .react-calendar {
    border-radius: 6px;
    margin-top: 6px;
}

.react-calendar__navigation__arrow {
    font-size: 22px !important;
}

input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 0.1em solid currentColor;
    border-radius: 50%;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
}

input[type="radio"]:focus {
    outline: none;
}
#schedule-time > .react-datetime-picker__wrapper {
    @apply bg-back !border-none;
}
#schedule-time > .react-datetime-picker__inputGroup {
    min-width: 0 !important;
    @apply bg-back;
}
#schedule-time > .react-datetime-picker__inputGroup__input,
#schedule-time > .react-datetime-picker__inputGroup__leadingZero,
#schedule-time > .react-datetime-picker__inputGroup__divider {
    @apply bg-back;
    font-family: Proxima Nova Regular !important;
}
